<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_45226_15184)">
      <path
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
        :fill="bgColor"
      />
      <path
        d="M15.5682 9.25405C15.6912 9.25405 15.8029 9.31043 15.8911 9.40569C15.9734 9.50743 16.0148 9.6338 16.0028 9.76729C16.0028 9.81136 15.6738 14.1791 15.4859 16.0175C15.3683 17.1458 14.6756 17.8307 13.6365 17.8495C12.8376 17.8683 12.0566 17.8748 11.2877 17.8748C10.4713 17.8748 9.67296 17.8683 8.89801 17.8495C7.89377 17.8242 7.20046 17.127 7.08881 16.0175C6.89553 14.1726 6.57258 9.81136 6.56658 9.76729C6.56058 9.6338 6.6014 9.50743 6.68423 9.40569C6.76587 9.31043 6.88352 9.25405 7.00718 9.25405H15.5682ZM12.5597 4.91406C13.1053 4.91406 13.5928 5.3139 13.7338 5.88416L13.8347 6.35723C13.9163 6.7428 14.2344 7.01562 14.6006 7.01562H16.4002C16.6403 7.01562 16.8402 7.22494 16.8402 7.49128V7.73753C16.8402 7.99739 16.6403 8.21318 16.4002 8.21318H6.17106C5.93036 8.21318 5.73047 7.99739 5.73047 7.73753V7.49128C5.73047 7.22494 5.93036 7.01562 6.17106 7.01562H7.97066C8.33622 7.01562 8.65437 6.7428 8.7366 6.35787L8.83084 5.91592C8.97731 5.3139 9.45932 4.91406 10.011 4.91406H12.5597Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_45226_15184">
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H17C20.3137 0 23 2.68629 23 6V17C23 20.3137 20.3137 23 17 23H6C2.68629 23 0 20.3137 0 17V6Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

    <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 23,
    },
    height: {
      type: [Number, String],
      default: 23,
    },
    iconColor: {
      type: String,
      default: "#ff6961",
    },
    bgColor: {
      type: String,
      default: "#F89520",
    },
  },
};
</script>
    