<template>
  <div
    class="py-3 text-center text-sm font-proximaNormal rounded-md cursor-pointer border flex items-center justify-center"
    :class="[width, background, paddingX, borderColor, textCl]"
  >
    <component :is="component" width="20" height="20" :iconColor="iconColor" />
    <span class="ml-2">{{ title }}</span>
  </div>
</template>

<script>
import BarcodeIcon from "../icons/BarcodeIcon.vue";
import ClipboardCheckIcon from "../icons/ClipboardCheckIcon.vue";
import LinkIcon from "../icons/LinkIcon.vue";
import ShareIcon from "../icons/ShareIcon.vue";
import ScanIcon from "../icons/ScanIcon.vue";
import PaperclipIcon from "../icons/PaperclipIcon.vue";
import CameraIcon from "../icons/CameraIcon.vue";
import TrashIcon from "../icons/Trash2Icon.vue";
import ResendIcon from "../icons/ResendIcon.vue";

export default {
  props: {
    component: {
      type: [String],
      default: "BarcodeIcon",
    },
    iconColor: {
      type: [String],
      default: "#0AC0CB",
    },
    width: {
      type: [String],
      default: "w-full",
    },
    textCl: {
      type: [String],
      default: "text-white",
    },
    paddingX: {
      type: [String],
      default: "px-20",
    },
    borderColor: {
      type: [String],
      default: "border-transparent",
    },
    title: {
      type: [String],
      default: "this is title",
    },
    background: {
      type: [String],
      default: "bg-transparent",
    },
  },
  components: {
    BarcodeIcon,
    ClipboardCheckIcon,
    LinkIcon,
    ShareIcon,
    ScanIcon,
    PaperclipIcon,
    CameraIcon,
    TrashIcon,
    ResendIcon,
  },
};
</script>