<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0227 0C8.8341 0 5.77607 1.2116 3.52138 3.36827C1.26668 5.52494 0 8.45001 0 11.5C0 17.8504 5.38248 23 12.0227 23C18.6617 23 24.0455 17.8504 24.0455 11.5C24.0455 5.14846 18.6617 0 12.0227 0ZM12.0227 17.6923C10.4431 17.6917 8.9181 17.1397 7.73386 16.1398C6.54962 15.14 5.78761 13.7611 5.59088 12.2619L5.07914 12.7514L3.77205 11.5L6.42939 8.95702L9.1792 11.4717L7.89924 12.7491L7.48369 12.3693C7.65552 13.1755 8.05826 13.9201 8.64692 14.5201C9.23559 15.12 9.98702 15.5516 10.8173 15.7667C11.6476 15.9818 12.524 15.9719 13.3487 15.7381C14.1735 15.5044 14.914 15.0559 15.4877 14.4428L16.8614 15.6258C16.2511 16.2751 15.505 16.7946 14.6715 17.1505C13.8379 17.5065 12.9356 17.6911 12.0227 17.6923ZM17.6173 14.043L14.8687 11.5271L16.1474 10.2497L16.5445 10.6118C16.3704 9.80855 15.9667 9.0672 15.3784 8.47024C14.7901 7.87327 14.0402 7.44409 13.2121 7.23043C12.3841 7.01677 11.5103 7.02699 10.6879 7.25997C9.86556 7.49295 9.12687 7.93955 8.55402 8.5501L7.17911 7.36826C8.01063 6.48269 9.09096 5.84468 10.2912 5.53034C11.4914 5.216 12.7609 5.23862 13.948 5.5955C15.1352 5.95238 16.1899 6.62845 16.9864 7.54302C17.7828 8.45759 18.2874 9.57204 18.4398 10.7534L18.9663 10.2486L20.2746 11.5L17.6173 14.043Z"
      :fill="bgColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 23,
    },
    height: {
      type: [Number, String],
      default: 23,
    },
    iconColor: {
      type: String,
      default: "#ff6961",
    },
    bgColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>
    